@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}


/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #97d5e8;
  border-radius: 5x;
  -webkit-border-radius: 5x;
  -moz-border-radius: 5x;
  -ms-border-radius: 5x;
  -o-border-radius: 5x;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(92, 92, 92);
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(92, 92, 92);
}

.hide-scroll-bar::-webkit-scrollbar {
  /* display: none;
  box-shadow: none; */
}

.hide-scroll-bar::-webkit-scrollbar-track {
  box-shadow: none;
}

.scroll-container {
  scroll-behavior: auto;
  overflow-y: scroll;
  height: 100vh;
}

.scroll-container::-webkit-scrollbar {
  display: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.left-sidebar,
.right-sidebar {
  /* background-color:#CBDFFC; */
  background-color: #CAEDFF;
  height: 100vh;
}

.left-sidebar {
  padding: 20px 12px !important;
}

.logout-wrapper {
  background-color: white;
  cursor: pointer;
  border-radius: 3px;
  bottom: 8px;
  position: absolute !important;
  width: 90%;
}

.btn-primary {
  background-color: #D02144 !important;
  border: 0 !important;
}

.btn-outline-primary {
  color: #D02144 !important;
  border-color: #D02144 !important;
}

.btn-outline-primary:hover {
  color: #fff !important;
  background-color: #D02144 !important;
  border-color: #D02144 !important;
}

.hr-sect {
  display: flex;
  flex-basis: 100%;
  align-items: center;
  color: rgba(0, 0, 0, 0.35);
  margin: 8px 0px;
}

.hr-sect::before,
.hr-sect::after {
  content: "";
  flex-grow: 1;
  background: rgba(0, 0, 0, 0.35);
  height: 1px;
  font-size: 0px;
  line-height: 0px;
  margin: 0px 8px;
}

.ms-icon {
  left: 128px;
}

/* .ms-btn{
  border: solid 1px rgb(190, 183, 183)!important;
} */

.company-logo-wrapper {
  padding: 16px;
}

.company-big-logo-wrapper {
  text-align: -webkit-center;
}

.company-big-logo-wrapper .logo {
  height: 85px;
}

.login-main-wrapper {
  margin-bottom: auto;
  margin-top: 35px;
  margin-right: auto;
  margin-left: auto;
}

/* .main-wrapper{
  width: calc(100% - -11px)!important;
  padding: 0px!important;
} */

.chat-input-box-wrapper {
  /* background: antiquewhite; */
  position: absolute;
  bottom: 6px;
  width: 98%;
  border: 1px solid #cecece;
  left: 23px;
  border-radius: 5px;
}

.input-icon-wrapper {
  position: absolute;
  bottom: 13px;
  right: 6px;
  padding: 0;
  justify-content: space-between;
}

.chat-input-box {
  border-right: 1px solid #cecece;
  ;
  border-radius: 0;
  height: -webkit-fill-available;
  width: 78% !important;
}

.chat-input-box-wrapper .textarea:focus-visible {
  outline: none !important;
}

textarea:focus-visible {
  outline: none !important;
}

.chat-wrapper {
  height: 100vh;
}

.response-chat-wrapper {
  background-color: #CBDFFC;
}

.new-chat-btn {
  padding: 10px;
  border: 1px solid rgb(222 222 222);
  background-color: white;
  display: flex;
  justify-content: center;
  border-radius: 3px;
}

.chat-chip-wrapper {
  padding: 3px;
  background: white;
  font-size: 13px;
  border-radius: 3px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.chat-chip-btn ol {
  padding-left: 0 !important;
  /* margin-bottom: -5px!important; */
}

.chat-chip-hover:hover {
  padding: 3px;
  background: white;
  font-size: 13px;
  border-radius: 3px;
  margin-top: 5px;
  margin-bottom: 5px;
  cursor: default;
}

.chat-chip-hover {
  font-size: 13px;
  padding: 3px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.edit-prompt-modal {
  margin-right: 10px;
}

.chat-chip-label {
  width: 140px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.left-sidebar .chat-chip-btn-wrapper {
  max-height: 70vh;
  overflow-y: auto;
  overflow-x: hidden;
  margin-right: -11px;
}

.chat-input-msg-icon {
  width: 4.333333% !important;
  margin-top: 12px !important;
  margin-bottom: auto;
}

.sharepoint-icon {
  height: 25px;
}

.top-default-heading-wrapper {
  text-align: center;
  margin-top: 60px;
}

.top-default-heading-wrapper .main-top-heading {
  font-size: 20px;
  margin-bottom: 7px;
}

.top-default-heading-wrapper .main-top-sub-heading {
  font-size: 14px;
}

/* .right-sidebar{
  padding: 20px;
  padding-right: 10px!important;
  padding-left: 14px;
} */

.right-sidebar .chat-chip-label {
  line-height: 25px;
}

.right-sidebar .nav-tabs .nav-item {
  padding-top: 5px;
  /* padding-bottom: 25px; */
}

.right-sidebar .nav-tabs .nav-item .nav-link {
  background: transparent;
  border: 0;
  color: #000;
  /* padding-left: 10px!important;
  padding-right: 30px!important; */
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  border-bottom: 3px solid #6B8CFF !important;
}

.right-sidebar .nav-tabs {
  border-bottom: 1px solid #c3bbbb;
  /* margin-left: -12px; */
  /* margin-right: -12px; */
  margin-bottom: 25px;
  display: flex;
  flex-wrap: nowrap;
}

.accordion .accordion-item {
  border-radius: 0 !important;
  -webkit-border-radius: 0 !important;
  -moz-border-radius: 0 !important;
  -ms-border-radius: 0 !important;
  -o-border-radius: 0 !important;
}

.accordion .accordion-item .accordion-button {
  /* background: #87C4FF; */
  background: #9cd7f6;
  border-radius: 0 !important;
  -webkit-border-radius: 0 !important;
  -moz-border-radius: 0 !important;
  -ms-border-radius: 0 !important;
  -o-border-radius: 0 !important;
  font-size: 14px;
}

.accordion .accordion-item .accordion-button:focus {
  z-index: 3;
  border-color: unset !important;
  outline: 0 !important;
  box-shadow: unset !important;
}

.accordion-body p {
  margin-bottom: 10px !important;
}

.userdefined-body-wrapper {
  padding: 13px !important;
}

/* .userdefined-body-wrapper .chat-list-item{
  height: 35px;
  display: block;
} */

.userdefined-body-wrapper .chat-list-item .chat-chip-hover {
  padding-bottom: 0;
}

.userdefined-body-wrapper .chat-chip-btn-wrapper {
  max-height: 35vh;
  overflow-y: auto;
  overflow-x: hidden;
  margin-right: -14px
}

.standard-body-wrapper {
  max-height: 55vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.configure-wrapper .form-floating .form-select {
  border-radius: 0 !important;
  -webkit-border-radius: 0 !important;
  -moz-border-radius: 0 !important;
  -ms-border-radius: 0 !important;
  -o-border-radius: 0 !important;
  padding-left: 13px;
  padding-top: 10px;
  font-size: 13px;
  font-weight: 600;
}

.configure-wrapper .form-floating .form-select:focus {
  border-color: unset !important;
  outline: 0 !important;
  box-shadow: unset !important;
  border: 0 !important;
}

.configure-wrapper .form-floating>.form-select~label {
  color: rgba(var(--bs-body-color-rgb), .65);
  transform: scale(0.85) translateY(-1.5rem) translateX(0.15rem);
  font-size: 14px;
}

select:not([multiple]) {
  -webkit-appearance: none;
  -moz-appearance: none;
  padding: .5em;
  padding-right: 1.5em
}

select {
  border-radius: 0;
  outline: none !important;
  -webkit-border-radius: 0 !important;
  -moz-border-radius: 0 !important;
  -ms-border-radius: 0 !important;
  -o-border-radius: 0 !important;
}

option {
  border-radius: 0 !important;
  -webkit-border-radius: 0 !important;
  -moz-border-radius: 0 !important;
  -ms-border-radius: 0 !important;
  -o-border-radius: 0 !important;
}

.form-floating:focus-visible {
  outline: unset !important;
}

*,
*:focus,
*:hover {
  outline: none !important;
}

.close-btn {
  right: 0;
  z-index: 1000;
  margin-right: -40px;
  border: 2px solid white;
  border-radius: 50%;
  padding: 3px;
  padding-top: 3px;
  padding-bottom: 1px;
  background: white;
  top: 0px;
}

.btn-close:focus {
  box-shadow: none !important;
  opacity: none;
  outline: none !important;
}

.form-floating>.form-control,
.form-floating>.form-control-plaintext {
  padding: 1rem 1.75rem !important;
  padding-top: 12px !important;
}

.form-floating>.form-control-plaintext~label,
.form-floating>.form-control:focus~label,
.form-floating>.form-control:not(:placeholder-shown)~label,
.form-floating>.form-select~label {
  color: rgba(var(--bs-body-color-rgb), .65);
  transform: scale(.85) translateY(-1.8rem) translateX(0.15rem) !important;
  -webkit-transform: scale(.85) translateY(-1.8rem) translateX(0.15rem) !important;
  -moz-transform: scale(.85) translateY(-1.8rem) translateX(0.15rem) !important;
  -ms-transform: scale(.85) translateY(-1.8rem) translateX(0.15rem) !important;
  -o-transform: scale(.85) translateY(-1.8rem) translateX(0.15rem) !important;
}

.form-floating>label {
  left: 16px !important;
}

.modal-body .form-floating .form-control {
  border-radius: 0 !important;
  -webkit-border-radius: 0 !important;
  -moz-border-radius: 0 !important;
  -ms-border-radius: 0 !important;
  -o-border-radius: 0 !important;
}

.modal-body .form-floating textarea {
  height: auto !important;
  min-height: 100px !important;
}

.modal-body .form-floating .form-control:focus {
  border-color: #dee2e6 !important;
  box-shadow: none !important;
}

.modal-body .form-floating label {
  color: #b9b6b6 !important;
  font-weight: 100 !important;
}

.configure-wrapper .form-floating .form-select {
  padding-left: 29px !important;
}

.file-upload-wrapper .sharepoint-icon {
  position: absolute;
  top: 16px;
  right: 12px;
}

.form-check-input:checked {
  background-color: #D02144 !important;
  border-color: #D02144 !important;
}

.form-check-input:focus {
  box-shadow: unset !important;
}

.form-check-input[type=radio] {
  border-radius: 50%;
  box-sizing: border-box;
  border: 2px solid rgba(0, 0, 0, .15);
}

.user-profile-name {
  padding: 2px 10px;
}

.admin-prompt-eye {
  height: 18px;
}

.folder-selection-wrapper {
  border: 1px solid #DEDDDD;
  padding: 12px 10px;
  border-radius: 9px;
  min-width: 150px;
  padding-left: 18px !important;
}

.sharepoint-folder-wrapper .form-check .form-check-input {
  margin-top: 17px;
}

.sharepoint-popup-wrapper {
  min-width: 610px;
}

#sharePointModal .modal-dialog .close-btn {
  right: 0;
  z-index: 1000;
  margin-right: -40px;
  border: 2px solid white;
  border-radius: 50%;
  padding: 3px;
  padding-top: 3px;
  padding-bottom: 1px;
  background: white;
}

/* #sharePointModal .modal-dialog{
	max-width: max-content;
} */

.sharepoint-popup-wrapper .modal-body {
  overflow-y: auto;
  max-height: 237px;
  margin-right: -24px
}

.main-selection-wrapper .form-check-input {
  margin-right: 15px;
  margin-top: 17px;
}


.folder-section .folder-wrapper .form-check-input {
  margin-top: 16px;
}

.folder-section .folder-wrapper {
  min-width: max-content;
}

@media (min-width: 992px) {

  .modal-lg,
  .modal-xl {
    --bs-modal-width: 650px !important;
  }
}

.sharepoint-popup-wrapper .modal-footer {
  margin-right: 5px;
}

.characters-limit-count {
  font-size: 12px;
}

.text-input-wrapper {
  width: 79% !important;
}

.character-count-wrapper {
  position: fixed;
  bottom: 77px;
  /* left: 1052px; */
  justify-content: end;
  display: flex;
  min-width: 54%;
}

.textbox-icon-wrapper {
  gap: 30px;
  margin-bottom: 7px;
}

.leading-loose {
  font-size: 13px;
}

.logout-wrapper .dropdown-toggle {
  white-space: nowrap;
  width: 100%;
  padding: 10px;
}

.dropdown-toggle:after {
  content: none !important;
}

.standard-body-wrapper .chat-chip-label {
  width: 116px !important;
}

.edit-btn {
  margin-right: 10px;
}

.left-sidebar .chat-chip-btn-wrapper .chat-chip-wrapper {
  margin-right: 7px;
  padding: 3px;
  background: white;
  font-size: 13px;
  border-radius: 3px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.left-sidebar .chat-chip-btn-wrapper .chat-chip-hover:hover {
  margin-right: 7px;
  padding: 3px;
  background: white;
  font-size: 13px;
  border-radius: 3px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.left-sidebar .chat-chip-btn-wrapper .chat-chip-hover {
  font-size: 13px;
  padding: 3px;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-right: 7px;
}

.pdf-extension-alert {
  margin: 0;
  position: absolute;
  top: 74px;
  font-size: 12px;
}

.edit-prompt-modal .close-btn {
  padding: 6px !important;
  padding-top: 3px !important;
  padding-bottom: 1px !important;
}

.user-details {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* .pdf-file-loader{
  
} */

.category-highlight {
  position: absolute;
  left: 3px;
  bottom: 0px;
  background-color: #f1f1f1;
  color: #0890d6;
  font-weight: 400;
  width: max-content;
  border-radius: 5px;
}

/* .fw-medium[class*="PDF Link"]{
  color: blue;
  background-color: yellow;
} */

/* .fw-medium[class*="PDF Link"] {
  color: #0890d6;
} */

@media only screen and (max-width : 1024px) {
  .main-wrapper {
    justify-content: space-between !important;
  }

  .left-sidebar {
    width: fit-content !important;
  }

  .chat-wrapper {
    width: 55% !important;
  }

  .chat-input-msg-icon {
    width: 7% !important;
  }

  .input-icon-wrapper {
    width: 28% !important;
  }

  .textbox-icon-wrapper {
    gap: 13px !important;
  }

  .right-sidebar {
    width: max-content !important;
  }

  .text-input-wrapper {
    width: 65% !important;
  }

}

@media only screen and (max-width : 769px) {

  .chat-wrapper {
    width: 70% !important;
  }

  .chat-input-box-wrapper {
    left: 16px;
  }

  .category-highlight {
    left: 0 !important;
  }

  .right-sidebar {
    width: auto !important;
  }
}

.navbar-toggler:focus {
  box-shadow: none !important;
  outline: none !important;
}

.navbar-toggler {
  border: none !important;
}

.offcanvas-body {
  padding: 0 !important;
}

.offcanvas {
  max-width: 35% !important;
}

.modal-backdrop:nth-child(2n-1) {
  opacity : 0!important;
  display:none;
}

.disclaimer{
  font-size: 13px;
  width: 61%;
  text-align: left;
}